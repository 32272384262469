export const environment = {
  production: false,

  hash: '8hDwccSyb2',

  uris: {
    // self: 'http://localhost:8100',
    self: 'https://planetwalker.gamebus.eu/',
    selfprod: 'https://planetwalker.gamebus.eu/',

    assets: 'https://planetwalker.gamebus.eu/assets/static',

    api: 'https://api-new.gamebus.eu/v2',

    airbridge: 'https://airbridge-api.gamebus.eu',
    // airbridge: 'http://localhost:3000',

  },

  client: {
    id: 'Gamebus_Studio_Levels1',
    secret: 'f8#fI!*MDQEPs9@!#FD0asfh213',
    dataprovider: 'Gamebus_Studio_Levels1',
  },

  languages: ['en', 'nl'], // Default language first

  // Default consent items
  consent: [
    { tk: 'name-visible', required: true },
    { tk: 'terms-conditions', required: true, link: 'https://blog.gamebus.eu/?page_id=1066' }
  ],

};
